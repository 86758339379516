import * as React from "react";
import SplitPanel from "../SplitPanel";
import SensorGroup from "../../Model/SensorGroup";
import Services from "../../Services/Platform/Services";
import SensorSelectPanel from "./Primary/SensorSelectPanel";
import { Reading, Sensor } from "@inductosense/typescript-fetch/dist/models";
import ItemList from "../../Components/ItemList";
import { CircularProgress } from "@mui/material";
import GetAllSensorsFromGroup from "Utilities/GetAllSensorsFromGroup";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Button from "../../Components/Buttons/Button";

interface SelectComparisonSignalPanelProps {
    onSelect(readingId: string, sensor: Sensor): void;
    initialSensorId: string;
}

interface SelectComparisonSignalPanelState {
    rootSensorGroup?: SensorGroup;
    sensor?: Sensor;
    sensorReadings?: Reading[];
    isLoadingReadings: boolean;
    selectedId?: string;
    fromDate: Date;
    toDate: Date;
}

export default class SelectComparisonSignalPanel extends React.Component<SelectComparisonSignalPanelProps, SelectComparisonSignalPanelState> {
    constructor(props: SelectComparisonSignalPanelProps) {
        super(props);

        this.state = {
            isLoadingReadings: false,
            fromDate: new Date(0),
            toDate: new Date()
        };
    }

    async componentDidMount() {
        const rootSensorGroup = await Services.SensorGroups.getRootSensorGroup();
        const allSensors = GetAllSensorsFromGroup(rootSensorGroup);
        const theSensor = [...allSensors.values()].find(s => s.id === this.props.initialSensorId);

        this.setState({
            rootSensorGroup
        });

        if (theSensor) {
            await this.onSensorSelect([theSensor.id], [theSensor]);
        }
    }

    async loadReadings() {
        const { sensor, fromDate, toDate } = this.state;

        const maxReadingsCount = 100;

        if (sensor !== undefined) {
            const sensorReadings = await Services.SensorReadingsService.sensorsIdReadingsGet(sensor.id, fromDate.toUTCString(), toDate.toUTCString(), maxReadingsCount);
            this.setState({
                sensorReadings,
                isLoadingReadings: false,
                fromDate: sensorReadings.length > 0 ? sensorReadings[sensorReadings.length - 1].timestamp : new Date(0),
                toDate: sensorReadings.length > 0 ? sensorReadings[0].timestamp : new Date()
            });
        }
    }

    async onSensorSelect(_sensorIds: string[], sensors: Sensor[]) {
        if (sensors.length === 0) throw new Error("sensors list is empty");
        this.setState({ isLoadingReadings: true });
        console.log("the sensor", sensors[0]);
        this.setState({ sensor: sensors[0] }, async () => {
            await this.loadReadings();
        });
    }

    render() {
        return <div style={{ width: 1300, height: 450 }}>
            {this.content()}
        </div>;
    }

    content() {
        const { rootSensorGroup, sensor, isLoadingReadings, sensorReadings, fromDate, toDate } = this.state;
        if (rootSensorGroup === undefined) return <p>Loading sensors...</p>;

        return (
            <SplitPanel
                firstContent={<SensorSelectPanel
                    rootSensorGroup={rootSensorGroup}
                    onSensorSelect={this.onSensorSelect.bind(this)}
                    selectedIds={sensor ? [sensor.id] : []}
                />}
                secondContent={
                    <div>
                        <div>
                            <Button label="Reset Date Filter" onClick={() => this.setState({ fromDate: new Date(0), toDate: new Date() }, async () => {
                                await this.loadReadings();
                            })}></Button>
                        </div>
                        <p>Select a sensor to view its readings. The search is limited to 100 readings at a time. Choose a date to see the latest 100 readings up to that point</p>
                        <div style={{ paddingTop: 20 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Date From"
                                    disabled
                                    value={dayjs(fromDate)}
                                    onChange={d => {
                                        if (d) {
                                            this.setState({ fromDate: d.toDate() }, async () => {
                                                await this.loadReadings();
                                            });
                                        }
                                    }}
                                    slotProps={{ textField: {} }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />

                                <DateTimePicker
                                    label="Date To"
                                    value={dayjs(toDate)}
                                    onChange={d => {
                                        if (d) {
                                            this.setState({ toDate: d.toDate() }, async () => {
                                                await this.loadReadings();
                                            });
                                        }
                                    }}
                                    slotProps={{ textField: { style: { marginLeft: 20, marginRight: 20 } } }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </LocalizationProvider>
                        </div>
                        {
                            isLoadingReadings ?
                                <CircularProgress /> :
                                sensorReadings !== undefined && sensor ?
                                    <div style={{ height: 350, overflowY: "auto" }}>

                                        <ItemList
                                            items={this.state.sensorReadings?.map(r => r.timestamp.toLocaleString()) || []}
                                            showEditIcons={false}
                                            showCheckboxes={false}
                                            onSelectionChanged={itemsChecked => {
                                                const selectedIndex = itemsChecked.map((checked, index) => checked ? index : -1).filter(i => i !== -1)[0];

                                                this.props.onSelect(sensorReadings[selectedIndex].id, sensor);
                                            }}
                                            itemsChecked={[]}
                                        />
                                    </div>
                                    : null
                        }
                    </div>
                }
                splitRatio={0.42}
                plane="horizontal"
            />
        );
    }
}
