import * as React from "react";
import { Component, CSSProperties } from "react";
import { FormSection } from "Types/Forms";
import { Material, Sensorwarningparameters, Wavemode, Sensor, Thicknessalgorithm,/*, Trendperiodtype, Trendalgorithm*/ } from "@inductosense/typescript-fetch";
import DropDownList from "Components/Input/DropDownList";
import Form from "Forms/Form";
import InformationIcon from "Components/Graphics/Icons/InformationIcon";
import LightbulbIcon from "Components/Graphics/Icons/LightbulbIcon";
import PipeCrosssectionIcon from "Components/Graphics/Icons/PipeCrosssectionIcon";
import RadioButtonGroup from "Components/Buttons/RadioButtonGroup";
import SwitchWithLabel from "Components/Buttons/SwitchWithLabel";
import SensorType from "Model/SensorType";
import Services from "Services/Platform/Services";
import StringRepresentsAPositiveNumberOrNothingValidator from "Validation/Strings/StringRepresentsAPositiveNumberOrNothingValidator";
import StringRepresentsARealNumberOrNothingValidator from "Validation/Strings/StringRepresentsARealNumberOrNothingValidator";
import TextField from "Components/Input/TextField";
import Validator from "Validation/Validator";
import WarningIcon from "Components/Graphics/Icons/WarningIcon";
import VelocityText from "../../Components/Text/VelocityText";
import UiSettings from "../../Model/UiSettings";
import { ThicknessAlgorithm } from "../../Model/Enumerations";
import { Calculate, DateRange } from "@mui/icons-material";
import StringRepresentsAnIntegerOrNothingValidator from "../../Validation/Strings/StringRepresentsAnIntegerOrNothingValidator";
import * as MainCGI from "Services/Electron/MainCGI";
import Button from "../../Components/Buttons/Button";
import ButtonsFormFooter from "../Shared/ButtonsFormFooter";
import SaveButton from "../../Components/Buttons/Composite/SaveButton";
import CancelButton from "../../Components/Buttons/Composite/CancelButton";
import StringLengthMaximumValidator from "Validation/Strings/StringLengthMaximumValidator";
import SensorGroup from "Model/SensorGroup";
import { FlattenSensors } from "../../Services/Utility";
import { Alert } from "@mui/lab";
import StringRepresentsAValidRfidValidator from "../../Validation/Strings/StringRepresentsAValidRfidValidator";
import FieldIsRequiredValidator from "../../Validation/Fields/FieldIsRequiredValidator";

const footerStyle: CSSProperties = { marginTop: 18 };

interface ConfigureSensorFormProps {
    sensor: Sensor | null;
    sensorTypes: SensorType[];
    materials: Material[];
    warningParameters: Sensorwarningparameters | null;
    onSave(waitForGraphCreatedAfter?: Date): void;
    onCancel(): void;
    uiSettings: UiSettings;
    rootSensorGroup: SensorGroup;
}

interface ConfigureSensorFormState {
    nameFieldValue: string | null;
    rfidFieldValue: string | null;
    nodeRfidFieldValue0: string | null;
    nodeRfidFieldValue1: string | null;
    nodeRfidFieldValue2: string | null;
    nodeRfidFieldValue3: string | null;
    typeFieldValue: SensorType;
    accessoriesFieldValue: { echo: boolean; reach: boolean };
    chirpParameterValue: string;
    materialFieldValue: Material;
    thicknessCalculationModeFieldValue: ThicknessAlgorithm;
    temperatureCompensationModeFieldValue: boolean;
    useOverrideChirpParameterFieldValue: boolean;
    knownStartingThicknessFieldValue: string | null;
    warningThicknessFieldValue: string | null;
    dangerThicknessFieldValue: string | null;
    nominalThicknessFieldValue: string | null;
    shortTermCorrosionPeriodFieldValue: string | null;
    longTermCorrosionPeriodFieldValue: string | null;
    equipmentIdFieldValue: string | null;
    circuitIdFieldValue: string | null;
    subCircuitIdFieldValue: string | null;
    cmlIdFieldValue: string | null;
    measurementPositionFieldValue: string | null;
    sapEquipmentNumberFieldValue: string | null;
    measurementSetIdFieldValue: string | null;
    matrixLocationFieldValue: string | null;
    //shortTermCorrosionTimescaleFieldValue: string | null;
    //echo45FieldValue: boolean;
    //echo100FieldValue: boolean;
    //reach400FieldValue: boolean;
    userTriedToSave: boolean;
    isSaving: boolean;
    isRecalculating: boolean;
    progressText?: string;
    parentGroup?: SensorGroup;
}

export default class ConfigureSensorForm extends Component<ConfigureSensorFormProps, ConfigureSensorFormState> {
    private readonly nameFieldInitialValue: string | null;
    private readonly typeFieldInitialValue: SensorType;
    private readonly materialFieldInitialValue: Material;
    private readonly thicknessCalculationModeFieldInitialValue: ThicknessAlgorithm;
    private readonly temperatureCompensationModeFieldInitialValue: boolean;
    private readonly useOverrideChirpParameterFieldInitialValue: boolean;
    private readonly knownStartingThicknessFieldInitialValue: string | null;
    private readonly warningThicknessFieldInitialValue: string | null;
    private readonly dangerThicknessFieldInitialValue: string | null;
    private readonly nominalThicknessFieldInitialValue: string | null;
    private readonly shortTermCorrosionPeriodFieldInitialValue: string | null;
    private readonly longTermCorrosionPeriodFieldInitialValue: string | null;

    private readonly equipmentIdFieldInitialValue: string | null;
    private readonly circuitIdFieldInitialValue: string | null;
    private readonly subCircuitIdFieldInitialValue: string | null;
    private readonly cmlIdFieldInitialValue: string | null;
    private readonly measurementPositionFieldInitialValue: string | null;
    private readonly sapEquipmentNumberFieldInitialValue: string | null;
    private readonly measurementSetIdFieldInitialValue: string | null;
    private readonly matrixLocationFieldInitialValue: string | null;

    private readonly fieldIsRequiredValidator: Validator<string>;
    private readonly realNumberValidator: Validator<string>;
    private readonly positiveNumberValidator: Validator<string>;
    private readonly integerValidator: Validator<string>;
    private readonly lengthValidator: Validator<string>;
    private readonly rfidValidator: Validator<string>;

    private readonly blankSensor: Sensor = {
        "id": "2b6b1835-ab82-4c2a-bbb6-633ea726c3cf",
        "rfid": "",
        "created": new Date("2021-08-23T10:43:36.75Z"),
        "updated": new Date("2021-10-05T08:45:41.3340124Z"),
        "description": "",
        "parentGroupId": "43682f84-deb4-4d92-959d-9026e09d1359",
        "calibrationValue": 0,
        "structureMaterial": this.props.materials[0],
        "warningParameters": {
            "id": "13fe0531-2702-43ca-a118-08d984ea33a6",
            "description": "User-specified warning parameters for sensor \"Example Sensor\"",
            "created": new Date("2021-10-05T08:45:41.2823111Z"),
            "warningThicknessInMetres": null,
            "criticalThicknessInMetres": null,
            "warningRateInMetresPerYear": null,
            "critialRateInMetresPerYear": null
        },
        "structureNominalThicknessInMetres": null,
        "sensorType": this.props.sensorTypes.filter(t => t.productName === "B5R")[0],
        "thicknessAlgorithm": ThicknessAlgorithm.FirstArrival,
        "hasTemperatureCompensation": false,
        "shortTermCorrosionPeriodDays": 93,
        "longTermCorrosionPeriodDays": 365,
        "equipmentId": null,
        "sapEquipmentNumber": null,
        "measurementSetId": null,
        "circuitId": null,
        "subCircuitId": null,
        "cmlId": null,
        "measurementPosition": null,
        "matrixLocation": null,
        "useOverrideChirpParameters": false
    };

    constructor(props: ConfigureSensorFormProps) {
        super(props);

        const { sensor, warningParameters } = props;
        const { calibrationValue, description, sensorType, structureMaterial, structureNominalThicknessInMetres,
            thicknessAlgorithm, hasTemperatureCompensation, useOverrideChirpParameters, shortTermCorrosionPeriodDays,
                longTermCorrosionPeriodDays, equipmentId, circuitId,
            subCircuitId, cmlId, measurementPosition, sapEquipmentNumber, measurementSetId, matrixLocation, rfid } = sensor || this.blankSensor;
        const { criticalThicknessInMetres, warningThicknessInMetres } = warningParameters || { };

        const name = description ?? null;
        const thicknessCalculationMode = thicknessAlgorithm;
        const knownStartingThickness = this.convertMetresUnits(calibrationValue || null);
        const warningThickness = this.convertMetresUnits(warningThicknessInMetres);
        const dangerThickness = this.convertMetresUnits(criticalThicknessInMetres);
        const nominalThickness = structureNominalThicknessInMetres ? this.convertMetresUnits(structureNominalThicknessInMetres) : null;
       
        if (sensorType === undefined) throw new Error("Sensor type should never be undefined at this point.");
        if (structureMaterial === undefined) throw new Error("Material should never be undefined at this point.");
        if (thicknessCalculationMode === undefined) throw new Error("Thickness calculation mode should never be undefined at this point.");

        this.nameFieldInitialValue = name;
        this.typeFieldInitialValue = sensorType;
        this.materialFieldInitialValue = structureMaterial;
        this.thicknessCalculationModeFieldInitialValue = thicknessCalculationMode;
        this.temperatureCompensationModeFieldInitialValue = hasTemperatureCompensation === undefined ? true : hasTemperatureCompensation;
        this.useOverrideChirpParameterFieldInitialValue = useOverrideChirpParameters === undefined ? true : useOverrideChirpParameters;
        this.knownStartingThicknessFieldInitialValue = knownStartingThickness;
        this.warningThicknessFieldInitialValue = warningThickness;
        this.dangerThicknessFieldInitialValue = dangerThickness;
        this.nominalThicknessFieldInitialValue = nominalThickness;
        this.shortTermCorrosionPeriodFieldInitialValue = shortTermCorrosionPeriodDays.toString();
        this.longTermCorrosionPeriodFieldInitialValue = longTermCorrosionPeriodDays.toString();
        this.equipmentIdFieldInitialValue = equipmentId ?? null;
        this.circuitIdFieldInitialValue = circuitId ?? null;
        this.subCircuitIdFieldInitialValue = subCircuitId ?? null;
        this.cmlIdFieldInitialValue = cmlId ?? null;
        this.measurementPositionFieldInitialValue = measurementPosition ?? null;
        this.sapEquipmentNumberFieldInitialValue = sapEquipmentNumber ?? null;
        this.measurementSetIdFieldInitialValue = measurementSetId ?? null;
        this.matrixLocationFieldInitialValue = matrixLocation ?? null;

        this.fieldIsRequiredValidator = new FieldIsRequiredValidator();
        this.realNumberValidator = new StringRepresentsARealNumberOrNothingValidator();
        this.positiveNumberValidator = new StringRepresentsAPositiveNumberOrNothingValidator();
        this.integerValidator = new StringRepresentsAnIntegerOrNothingValidator();
        this.lengthValidator = new StringLengthMaximumValidator(30);
        this.rfidValidator = new StringRepresentsAValidRfidValidator();

        const flatGroups = FlattenSensors(props.rootSensorGroup, "asc");
        const parentGroup = props.sensor !== null ? flatGroups.groups.get(props.sensor.parentGroupId) : undefined;

        this.state = {
            nameFieldValue: name,
            rfidFieldValue: rfid || null,
            nodeRfidFieldValue0: "",
            nodeRfidFieldValue1: "",
            nodeRfidFieldValue2: "",
            nodeRfidFieldValue3: "",
            typeFieldValue: sensorType,
            accessoriesFieldValue: { echo: false, reach: false },
            chirpParameterValue: "1",
            materialFieldValue: structureMaterial,
            thicknessCalculationModeFieldValue: thicknessCalculationMode,
            temperatureCompensationModeFieldValue: hasTemperatureCompensation === undefined ? true : hasTemperatureCompensation,
            useOverrideChirpParameterFieldValue: useOverrideChirpParameters === undefined ? true : useOverrideChirpParameters,
            knownStartingThicknessFieldValue: knownStartingThickness,
            warningThicknessFieldValue: warningThickness,
            dangerThicknessFieldValue: dangerThickness,
            nominalThicknessFieldValue: nominalThickness,
            shortTermCorrosionPeriodFieldValue: this.shortTermCorrosionPeriodFieldInitialValue,
            longTermCorrosionPeriodFieldValue: this.longTermCorrosionPeriodFieldInitialValue,
            equipmentIdFieldValue: this.equipmentIdFieldInitialValue,
            circuitIdFieldValue: this.circuitIdFieldInitialValue,
            subCircuitIdFieldValue: this.subCircuitIdFieldInitialValue,
            cmlIdFieldValue: this.cmlIdFieldInitialValue,
            measurementPositionFieldValue: this.measurementPositionFieldInitialValue,
            sapEquipmentNumberFieldValue: this.sapEquipmentNumberFieldInitialValue,
            measurementSetIdFieldValue: this.measurementSetIdFieldInitialValue,
            matrixLocationFieldValue: this.matrixLocationFieldInitialValue,
            //shortTermCorrosionTimescaleFieldValue: null,
            //echo45FieldValue: false,
            //echo100FieldValue: false,
            //reach400FieldValue: false,
            userTriedToSave: false,
            isSaving: false,
            isRecalculating: false,
            parentGroup
        };
    }

    private convertMetresUnits(input: number | undefined | null) {
        if (input === undefined) return null;
        if (input === null) return null;

        if (this.props.uiSettings.unitsMode === "imperial") {
            return (input * 39.37).toString();
        } else {
            return (input * 1000).toString();
        }
    }

    private convertUnitsToMetres(input: string | null) {
        if (input === null) return null;
        if (input === "") return null;

        if (this.props.uiSettings.unitsMode === "imperial") {
            return parseFloat(input) / 39.37;
        } else {
            return parseFloat(input) / 1000;
        }
    }

    private getDisplacementUnits() {
        if (this.props.uiSettings.unitsMode === "imperial") {
            return "\"";
        } else {
            return "mm";
        }
    }

    render() {
        return <>
            {this.state.parentGroup?.name?.includes("MES") ?
                <Alert severity="info">
                    This is a multi-element sensor node
                </Alert>
            : null}
            <Form
                tabs={this.tabs()}
                footer={this.footer()}
                onSubmit={() => {
                    // Return can be used to recalculate sensor even if it hasn't changed and save button is disabled
                    if (!this.state.isSaving) {
                        this.onSaveButtonClick();
                    }
                }}
            />
        </>;
    }

    // TODO: Server-side support for separating short- and long-term trending to be added in work item #444.
    // TODO: Server-side support for ancillary products to be added in work item #429.
    private tabs() {
        return [{
            id: "tab-sensor-config-general",
            label: "General",
            sections: [
                this.rfidSection(),
                this.nameSection(),
                this.typeSection(),
                this.nodeRfidSections(),
                this.chirpParameterOptionSection(),
                this.materialSection(),
                this.thicknessCalculationModeSection(),
                this.temperatureCompensationToggleSection(),
                this.knownStartingThicknessSection(),
                this.warningThicknessSection(),
                this.dangerThicknessSection(),
                this.nominalThicknessSection(),
                this.shortTermCorrosionPeriodSection(),
                this.longTermCorrosionPeriodSection()
                //this.shortTermCorrosionTimescaleSection(),
                //this.ancillaryProductsSection()
            ]
        },
        {
            id: "tab-sensor-config-location",
            label: "Location",
            sections: [
                this.equipmentIdSection(),
                this.circuitIdSection(),
                this.subCircuitIdSection(),
                this.cmlIdSection(),
                this.measurementPositionSection(),
                this.sapEquipmentNumberSection(),
                this.measurementSetIdSection(),
                this.matrixLocationSection(),
            ]
        }
        ];
    }

    private footer() {
        const { progressText } = this.state;

        return (
            <div style={footerStyle}>
                {progressText !== undefined ? <p>{progressText}</p> : null}

                <ButtonsFormFooter
                    buttons={[
                        <Button
                            key="recalculate"
                            label="Recalculate"
                            icon={<Calculate />}
                            isSpinning={this.state.isRecalculating}
                            onClick={() => this.onRecalculateButtonClick()}
                        />,
                        <SaveButton
                            key="save"
                            isDisabled={this.formValuesAreUnchanged() || !this.formValuesAreValid()}
                            isSpinning={this.state.isSaving || this.state.isRecalculating}
                            onClick={() => this.onSaveButtonClick()}
                        />,
                        <CancelButton
                            key="cancel"
                            isDisabled={this.state.isSaving}
                            onClick={() => this.onCancelButtonClick()}
                        />
                    ]}
                />
            </div>
        );
    }

    private nodeRfidSections(): FormSection {

        return {
            marginTop: 4,
            label: { text: "Node RFIDs", isRequired: true, marginTop: 7 },
            isHidden: this.state.typeFieldValue.productName !== "MES" || this.props.sensor !== null,
            control: {
                isEditable: true,
                node: ([
                    <TextField
                        key={"nodeRfid0"}
                        textAlignment="right"
                        width={261}
                        initialValue={this.state.nodeRfidFieldValue0}
                        isDisabled={this.state.isSaving}
                        onNewValue={nv => this.setState({ nodeRfidFieldValue0: nv })}
                    />,
                    <TextField
                        key={"nodeRfid1"}
                        textAlignment="right"
                        width={261}
                        initialValue={this.state.nodeRfidFieldValue1}
                        isDisabled={this.state.isSaving}
                        onNewValue={nv => this.setState({ nodeRfidFieldValue1: nv })}
                    />,
                    <TextField
                        key={"nodeRfid2"}
                        textAlignment="right"
                        width={261}
                        initialValue={this.state.nodeRfidFieldValue2}
                        isDisabled={this.state.isSaving}
                        onNewValue={nv => this.setState({ nodeRfidFieldValue2: nv })}
                    />,
                    <TextField
                        key={"nodeRfid3"}
                        textAlignment="right"
                        width={261}
                        initialValue={this.state.nodeRfidFieldValue3}
                        isDisabled={this.state.isSaving}
                        onNewValue={nv => this.setState({ nodeRfidFieldValue3: nv })}
                    />
                ])
            }
        };
    }

    private rfidSection(): FormSection {
        if (this.props.sensor !== null) {
            return {
                marginTop: 7,
                label: { text: "RFID" },
                control: {
                    isEditable: false,
                    node: this.props.sensor?.rfid
                }
            };
        } else {
            return {
                marginTop: 4,
                label: { text: "RFID", isRequired: true, marginTop: 7 },
                control: {
                    isEditable: true,
                    node: (
                        <TextField
                            textAlignment="right"
                            width={261}
                            initialValue={this.state.rfidFieldValue}
                            isDisabled={this.state.isSaving}
                            onNewValue={nv => this.onRfidFieldUpdated(nv || "")}
                        />
                    )
                }
            };
        }
    }

    private nameSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "Name", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={200}
                        initialValue={this.nameFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onNameFieldUpdated(newValue)}
                    />
                )
            }
        };
    }

    private isMESOptionAvailable() {
        if (this.typeFieldInitialValue.productName === "MES") return true;
        if (this.props.sensor === null) return true;

        return false;
    }

    private typeSection(): FormSection {
        return {
            marginTop: 6,
            label: { text: "Type", isRequired: true, marginTop: 10 },
            control:
                this.typeFieldInitialValue.productName === "MES" ?
                    {
                        isEditable: false,
                        node: this.props.sensor?.sensorType.productName
                    } : 
                    {
                        isEditable: true,
                        node: (      
                            <RadioButtonGroup<SensorType>
                                options={
                                    this.props.sensorTypes
                                        .filter(t => t.productName !== "MES" || this.isMESOptionAvailable())
                                        .sort((a, b) => a.productName
                                        .localeCompare(b.productName))
                                }
                                labelFor={option => option.productName ?? option.toString()}
                                labelPositions="left"
                                initiallySelectedOption={this.typeFieldInitialValue}
                                isDisabled={this.state.isSaving}
                                onChange={(newSelectedOption => this.onTypeFieldUpdated(newSelectedOption))}
                                orientation="horizontal"
                            />
                        )
                    }
        };
    }

    private chirpParameterOptionSection(): FormSection {
        const { chirpParameters, overrideChirpParameters } = this.state.typeFieldValue;

        return {
            marginTop: 11,
            label: { text: "Chirp parameters", isRequired: true, marginTop: 5 },
            isHidden: !this.roleShowsChirpParameter() || this.typeFieldInitialValue.productName === "MES",
            control: {
                isEditable: true,
                node: (
                    <DropDownList<"normal" | "override">
                        textAlignment="right"
                        width={200}
                        initiallySelectedOption={this.props.sensor?.useOverrideChirpParameters ? "override" : "normal"}
                        isDisabled={this.state.isSaving}
                        onChange={((newSelectedOption) => this.onChirpParameterUpdated(newSelectedOption === "override"))}
                        options={this.getAvailableChirpParameterOptions()}
                        labelFor={(option) => {
                            if (option === "override") {
                                return <div>{((overrideChirpParameters?.centreFrequencyInKHz || 0) / 1000) + " MHz"}</div>;
                            } else {
                                return <div>{(chirpParameters?.centreFrequencyInKHz / 1000) + " MHz"}</div>;
                            }
                        }}
                    />
                )
            }
        };
    }

    private materialSection(): FormSection {
        return {
            marginTop: 11,
            label: { text: "Material", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <DropDownList<Material>
                        textAlignment="right"
                        minimumWidth={261}
                        initiallySelectedOption={this.materialFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onChange={newSelectedOption => this.onMaterialFieldUpdated(newSelectedOption)}
                        options={this.props.materials.sort((a, b) => {
                            const aDisplayName = a.name ?? a.id;
                            const bDisplayName = b.name ?? b.id;

                            if (!aDisplayName || !bDisplayName) throw new Error("Material display names should never be undefined at this point.");

                            return aDisplayName.localeCompare(bDisplayName);
                        })}
                        labelFor={({ id, name, longitudinalVelocityAtRoomTemperatureInMetresPerSecond, shearVelocityAtRoomTemperatureInMetresPerSecond }) => {
                            const displayName = name ?? id;

                            const velocityInMetresPerSecond = this.state.typeFieldValue.ultrasonicWaveMode === Wavemode.Longitudinal
                                ? longitudinalVelocityAtRoomTemperatureInMetresPerSecond
                                : shearVelocityAtRoomTemperatureInMetresPerSecond;

                            return <>{displayName} (<VelocityText
                                velocityInMetresPerSecond={velocityInMetresPerSecond}
                                unitsMode={this.props.uiSettings.unitsMode}
                            />)</>;
                        }}
                    />
                )
            }
        };
    }

    private getAvailableThicknessOptions() {
        if (this.state.typeFieldValue.productName === "DL1") {
            return [ThicknessAlgorithm.SdlTrnd];
        }
        return [
            ...this.state.typeFieldValue.allowP2pThicknessAlgorithm ? [ThicknessAlgorithm.PeakToPeak] : [],
            ThicknessAlgorithm.FirstArrival
        ];
    }

    private getAvailableChirpParameterOptions(): ("normal" | "override")[] {
        const { chirpParameters, overrideChirpParameters } = this.state.typeFieldValue;
        
        if (chirpParameters.id == overrideChirpParameters?.id) {
            return [ "normal" ];
        }
        else {
            return [ "normal", "override" ];
        }
    }

    private thicknessCalculationModeSection(): FormSection {
        return {
            marginTop: 11,
            label: { text: "Thickness calculation mode", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <DropDownList<ThicknessAlgorithm>
                        textAlignment="right"
                        width={200}
                        initiallySelectedOption={this.thicknessCalculationModeFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onChange={newSelectedOption => this.onThicknessAlgorithmFieldUpdated(newSelectedOption)}
                        options={this.getAvailableThicknessOptions()}
                        labelFor={option => {
                            switch (option) {
                                case ThicknessAlgorithm.Unknown: return "Unknown";
                                case ThicknessAlgorithm.PeakToPeak: return "Peak-to-peak";
                                case ThicknessAlgorithm.FirstArrival: return "First arrival";
                                case ThicknessAlgorithm.FirstArrivalThicknessLoss: return "FA thickness loss";
                                case ThicknessAlgorithm.SdlTrnd: return "DL1 New";
                                default: throw new Error("Unexpected thickness algorithm encountered.");
                            }
                        }}
                    />
                )
            }
        };
    }

    private temperatureCompensationToggleSection(): FormSection {
        return {
            marginTop: 6,
            label: { text: "Temperature compensation mode", isRequired: true, marginTop: 10 },
            control: {
                isEditable: true,
                node: (
                    <SwitchWithLabel
                        labelWhenOn={""}
                        labelWhenOff={""}
                        initialState={this.state.temperatureCompensationModeFieldValue ? "on" : "off"}
                        onChange={newSelectedOption =>
                            this.onTemperatureCompensationButtonToggled(newSelectedOption == "on" ? true : false)}
                    />
                )
            }
        };
    }

    private knownStartingThicknessSection(): FormSection {
        const { isSaving } = this.state;
        const { knownStartingThicknessFieldInitialValue, positiveNumberValidator, realNumberValidator } = this;

        return {
            marginTop: 13,
            label: { text: "Known starting thickness", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        suffix={this.getDisplacementUnits()}
                        icon={<LightbulbIcon />}
                        width={225}
                        initialValue={knownStartingThicknessFieldInitialValue}
                        isDisabled={isSaving}
                        onNewValue={newValue => this.onKnownStartingThicknessFieldUpdated(newValue)}
                        validators={[
                            realNumberValidator,
                            positiveNumberValidator
                        ]}
                    />
                )
            }
        };
    }

    private warningThicknessSection(): FormSection {
        const { isSaving } = this.state;
        const { positiveNumberValidator, realNumberValidator, warningThicknessFieldInitialValue } = this;

        return {
            marginTop: 3,
            label: { text: "Warning thickness", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        suffix={this.getDisplacementUnits()}
                        icon={<InformationIcon shouldApplyDefaultColour={false} />}
                        width={225}
                        initialValue={warningThicknessFieldInitialValue}
                        isDisabled={isSaving}
                        onNewValue={newValue => this.onWarningThicknessFieldUpdated(newValue)}
                        validators={[
                            realNumberValidator,
                            positiveNumberValidator
                        ]}
                    />
                )
            }
        };
    }

    private dangerThicknessSection(): FormSection {
        const { isSaving } = this.state;
        const { dangerThicknessFieldInitialValue, positiveNumberValidator, realNumberValidator } = this;

        return {
            marginTop: 3,
            label: { text: "Minimum thickness", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        suffix={this.getDisplacementUnits()}
                        icon={<WarningIcon shouldApplyDefaultColour={false} />}
                        width={225}
                        initialValue={dangerThicknessFieldInitialValue}
                        isDisabled={isSaving}
                        onNewValue={newValue => this.onDangerThicknessFieldUpdated(newValue)}
                        validators={[
                            realNumberValidator,
                            positiveNumberValidator
                        ]}
                    />
                )
            }
        };
    }

    private nominalThicknessSection(): FormSection {
        const { isSaving } = this.state;
        const { nominalThicknessFieldInitialValue, positiveNumberValidator, realNumberValidator } = this;

        return {
            marginTop: 3,
            label: { text: "Nominal thickness", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        suffix={this.getDisplacementUnits()}
                        icon={<PipeCrosssectionIcon />}
                        width={225}
                        initialValue={nominalThicknessFieldInitialValue}
                        isDisabled={isSaving}
                        onNewValue={newValue => this.onNominalThicknessFieldUpdated(newValue)}
                        validators={[
                            realNumberValidator,
                            positiveNumberValidator
                        ]}
                    />
                )
            }
        };
    }

    private shortTermCorrosionPeriodSection(): FormSection {
        const { isSaving } = this.state;
        const { integerValidator, positiveNumberValidator, shortTermCorrosionPeriodFieldInitialValue } = this;

        return {
            marginTop: 3,
            label: { text: "Short-term corrosion period", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        suffix="days"
                        icon={<DateRange />}
                        width={225}
                        initialValue={shortTermCorrosionPeriodFieldInitialValue}
                        isDisabled={isSaving}
                        onNewValue={newValue => this.onShortTermCorrosionPeriodFieldUpdated(newValue)}
                        validators={[
                            integerValidator,
                            positiveNumberValidator
                        ]}
                    />
                )
            }
        };
    }

    private longTermCorrosionPeriodSection(): FormSection {
        const { isSaving } = this.state;
        const { integerValidator, positiveNumberValidator, longTermCorrosionPeriodFieldInitialValue } = this;

        return {
            marginTop: 3,
            label: { text: "Long-term corrosion period", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        suffix="days"
                        icon={<DateRange />}
                        width={225}
                        initialValue={longTermCorrosionPeriodFieldInitialValue}
                        isDisabled={isSaving}
                        onNewValue={newValue => this.onLongTermCorrosionPeriodFieldUpdated(newValue)}
                        validators={[
                            integerValidator,
                            positiveNumberValidator
                        ]}
                    />
                )
            }
        };
    }

    private equipmentIdSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "Equipment ID", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.equipmentIdFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onEquipmentIdFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    private circuitIdSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "Circuit ID", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.circuitIdFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onCircuitIdFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    private subCircuitIdSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "Sub-Circuit ID", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.subCircuitIdFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onSubCircuitIdFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    private cmlIdSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "CML ID", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.cmlIdFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onCmlIdFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    private measurementPositionSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "Measurement Position", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.measurementPositionFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onMeasurementPositionFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    private sapEquipmentNumberSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "SAP Equipment ID", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.sapEquipmentNumberFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onSapEquipmentNumberFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    private measurementSetIdSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "Measurement Set ID", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.measurementSetIdFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onMeasurementSetIdFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    private matrixLocationSection(): FormSection {
        return {
            marginTop: 4,
            label: { text: "Matrix Location", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        width={220}
                        initialValue={this.matrixLocationFieldInitialValue}
                        isDisabled={this.state.isSaving}
                        onNewValue={newValue => this.onMatrixLocationFieldUpdated(newValue)}
                        validators={[this.lengthValidator]}
                    />
                )
            }
        };
    }

    //private shortTermCorrosionTimescaleSection(): FormSection {
    //    return {
    //        label: { text: "Short-term corrosion timescale", marginTop: 5 },
    //        control: {
    //            isEditable: true,
    //            node: (
    //                <TextField
    //                    textAlignment="right"
    //                    suffix="days"
    //                    icon={<DownwardTrendIcon />}
    //                    width={225}
    //                    initialValue={this.state.shortTermCorrosionTimescaleFieldValue ?? ""}
    //                    isDisabled={this.state.isSaving}
    //                    onNewValue={newValue => this.onShortTermCorrosionTimescaleFieldUpdated(newValue)}
    //                />
    //            )
    //        }
    //    };
    //}

    //private ancillaryProductsSection(): FormSection {
    //    return AncillaryProductsFormSection({
    //        marginTop: 12,
    //        isSaving: this.state.isSaving,
    //        onEcho45ValueChanged: newValue => this.onEcho45FieldUpdated(newValue),
    //        onEcho100ValueChanged: newValue => this.onEcho100FieldUpdated(newValue),
    //        onReach400ValueChanged: newValue => this.onReach400FieldUpdated(newValue)
    //    });
    //}

    private roleShowsChirpParameter(){
        return Services.userHasPolicy("ViewChirpParametersOptions");
    }

    private ensureMESInName(input: string) {
        if (input.includes("MES")) {
            return input;
        } else {
            return input + " MES";
        }
    }

    private async onSaveButtonClick() {

        const { onSave } = this.props;

        const { dangerThicknessFieldValue, knownStartingThicknessFieldValue, materialFieldValue, nameFieldValue,
            nominalThicknessFieldValue, typeFieldValue, warningThicknessFieldValue, thicknessCalculationModeFieldValue,
            temperatureCompensationModeFieldValue, useOverrideChirpParameterFieldValue,
            shortTermCorrosionPeriodFieldValue, longTermCorrosionPeriodFieldValue, equipmentIdFieldValue,
            circuitIdFieldValue, subCircuitIdFieldValue, cmlIdFieldValue, measurementPositionFieldValue,
            sapEquipmentNumberFieldValue, measurementSetIdFieldValue, matrixLocationFieldValue, rfidFieldValue,
            nodeRfidFieldValue0, nodeRfidFieldValue1, nodeRfidFieldValue2, nodeRfidFieldValue3 } = this.state;

        this.setState({ isSaving: true });

        const sensor = this.props.sensor || this.blankSensor;

        const newWarningParametersId = await Services.Sensors.createNewWarningParametersForSensor(sensor, {
            warningThicknessInMetres: this.convertUnitsToMetres(warningThicknessFieldValue),
            criticalThicknessInMetres: this.convertUnitsToMetres(dangerThicknessFieldValue)
        });

        if (!this.state.userTriedToSave) this.setState({ userTriedToSave: true });
        if (this.formValuesAreValid()) {

            if (this.props.sensor === null) {

                if (this.state.typeFieldValue.productName === "MES") {

                    const groupName = this.ensureMESInName(nameFieldValue! /* TODO: Remove ! */);

                    const groupId = await Services.SensorGroups.addSensorGroup(groupName, "43682f84-deb4-4d92-959d-9026e09d1359", rfidFieldValue!); // TODO: Correct parent?

                    const nodeRfids = [nodeRfidFieldValue0, nodeRfidFieldValue1, nodeRfidFieldValue2, nodeRfidFieldValue3];
                    for (let i = 0; i < 4; i++) {
                        await Services.Sensors.createSensor({
                            rfid: nodeRfids[i] || undefined,
                            thicknessAlgorithm: Thicknessalgorithm.FirstArrival,
                            description: `${groupName} node ${i + 1}`,
                            calibrationValue: 0,
                            parentGroupId: groupId,
                            structureMaterialId: materialFieldValue.id,
                            warningParametersId: newWarningParametersId,
                            sensorTypeId: typeFieldValue.id, // This will be MES
                            shortTermCorrosionPeriodDays: 91,
                            longTermCorrosionPeriodDays: 361,
                            useOverrideChirpParameters: false
                        });
                    }

                    onSave();
                    return;
                }

                await Services.Sensors.createSensor({
                    description: nameFieldValue ?? undefined,
                    rfid: rfidFieldValue || undefined,
                    calibrationValue: knownStartingThicknessFieldValue ? +knownStartingThicknessFieldValue / 1e3 : 0, // TODO: Is 0 an acceptable default?
                    structureMaterialId: materialFieldValue.id,
                    warningParametersId: newWarningParametersId,
                    structureNominalThicknessInMetres: nominalThicknessFieldValue ? +nominalThicknessFieldValue / 1e3 : null,
                    sensorTypeId: typeFieldValue.id,
                    thicknessAlgorithm: thicknessCalculationModeFieldValue,
                    hasTemperatureCompensation: temperatureCompensationModeFieldValue,
                    useOverrideChirpParameters: useOverrideChirpParameterFieldValue,
                    shortTermCorrosionPeriodDays: parseInt(shortTermCorrosionPeriodFieldValue || "365"),
                    longTermCorrosionPeriodDays: parseInt(longTermCorrosionPeriodFieldValue || "93"),
                    equipmentId: equipmentIdFieldValue ?? undefined,
                    circuitId: circuitIdFieldValue ?? undefined,
                    subCircuitId: subCircuitIdFieldValue ?? undefined,
                    cmlId: cmlIdFieldValue ?? undefined,
                    measurementPosition: measurementPositionFieldValue ?? undefined,
                    sapEquipmentNumber: sapEquipmentNumberFieldValue ?? undefined,
                    measurementSetId: measurementSetIdFieldValue ?? undefined,
                    matrixLocation: matrixLocationFieldValue ?? undefined,
                    parentGroupId: "43682f84-deb4-4d92-959d-9026e09d1359" // TODO
                });

                onSave();
            } else {

                const sensorId = sensor.id;

                if (this.state.parentGroup?.name?.includes("MES")) {
                    for (const sensorNode of this.state.parentGroup?.sensors || []) {
                        await Services.Sensors.updateSensor(sensorNode.id,
                            {
                                ...sensorNode,
                                warningParametersId: sensorNode.warningParameters.id,
                                sensorTypeId: sensorNode.sensorType.id,
                                structureMaterialId: materialFieldValue.id,
                                shortTermCorrosionPeriodDays: parseInt(shortTermCorrosionPeriodFieldValue || sensorNode.shortTermCorrosionPeriodDays.toString()),
                                longTermCorrosionPeriodDays: parseInt(longTermCorrosionPeriodFieldValue || sensorNode.longTermCorrosionPeriodDays.toString())
                            });
                    }
                }

                const waitForGraphCreatedAfter = new Date();
                await Services.Sensors.updateSensor(sensorId, {
                    ...sensor,
                    description: nameFieldValue ?? undefined,
                    calibrationValue: knownStartingThicknessFieldValue ? +knownStartingThicknessFieldValue / 1e3 : 0, // TODO: Is 0 an acceptable default?
                    structureMaterialId: materialFieldValue.id,
                    warningParametersId: newWarningParametersId,
                    structureNominalThicknessInMetres: nominalThicknessFieldValue ? +nominalThicknessFieldValue / 1e3 : null,
                    sensorTypeId: typeFieldValue.id,
                    thicknessAlgorithm: thicknessCalculationModeFieldValue,
                    hasTemperatureCompensation: temperatureCompensationModeFieldValue,
                    useOverrideChirpParameters: useOverrideChirpParameterFieldValue,
                    shortTermCorrosionPeriodDays: parseInt(shortTermCorrosionPeriodFieldValue || "365"),
                    longTermCorrosionPeriodDays: parseInt(longTermCorrosionPeriodFieldValue || "93"),
                    equipmentId: equipmentIdFieldValue ?? undefined,
                    circuitId: circuitIdFieldValue ?? undefined,
                    subCircuitId: subCircuitIdFieldValue ?? undefined,
                    cmlId: cmlIdFieldValue ?? undefined,
                    measurementPosition: measurementPositionFieldValue ?? undefined,
                    sapEquipmentNumber: sapEquipmentNumberFieldValue ?? undefined,
                    measurementSetId: measurementSetIdFieldValue ?? undefined,
                    matrixLocation: matrixLocationFieldValue ?? undefined
                });

                //if (!this.formValuesForRecalculationAreUnchanged()) {
                await Services.Readings.updateParametersForSensorReadings(sensor.id, [], {});
                // TODO: Make sure we don't have to update parameters manually *
                //}

                onSave(waitForGraphCreatedAfter); // TODO: Only do this if recalc needed *

                await MainCGI.uploadDbAuto();
            }

            this.setState({ isSaving: false });
        }
    }

    private async onRecalculateButtonClick() {
        const { onSave } = this.props;
        const sensor = this.props.sensor || this.blankSensor;

        const waitForGraphCreatedAfter = new Date();

        //if (!this.formValuesForRecalculationAreUnchanged()) {
        await Services.Readings.updateParametersForSensorReadings(sensor.id, [], {});
        // TODO: Make sure we don't have to update parameters manually *
        //}

        onSave(waitForGraphCreatedAfter); // TODO: Only do this if recalc needed *
    }

    private onCancelButtonClick() {
        this.props.onCancel();
    }

    private onNameFieldUpdated(newValue: string | null) {
        this.setState({ nameFieldValue: newValue });
    }

    private onTypeFieldUpdated(newValue: SensorType) {
        this.setState({ typeFieldValue: newValue });
    }

    private onRfidFieldUpdated(newValue: string) {
        this.setState({ rfidFieldValue: newValue });
    }

    private onMaterialFieldUpdated(newValue: Material) {
        this.setState({ materialFieldValue: newValue });
    }

    private onThicknessAlgorithmFieldUpdated(newValue: ThicknessAlgorithm) {
        this.setState({ thicknessCalculationModeFieldValue: newValue });
    }

    private onTemperatureCompensationButtonToggled(newValue: boolean) {
        this.setState({ temperatureCompensationModeFieldValue: newValue });
    }
    
    private onChirpParameterUpdated(newValue: boolean) {
        this.setState({ useOverrideChirpParameterFieldValue: newValue });
    }

    private onWarningThicknessFieldUpdated(newValue: string | null) {
        this.setState({ warningThicknessFieldValue: newValue });
    }

    private onDangerThicknessFieldUpdated(newValue: string | null) {
        this.setState({ dangerThicknessFieldValue: newValue });
    }

    //private onShortTermCorrosionTimescaleFieldUpdated(newValue: string | null) {
    //    this.setState({ shortTermCorrosionTimescaleFieldValue: newValue });
    //}

    private onKnownStartingThicknessFieldUpdated(newValue: string | null) {
        this.setState({ knownStartingThicknessFieldValue: newValue });
    }

    private onNominalThicknessFieldUpdated(newValue: string | null) {
        this.setState({ nominalThicknessFieldValue: newValue });
    }

    private onShortTermCorrosionPeriodFieldUpdated(newValue: string | null) {
        this.setState({ shortTermCorrosionPeriodFieldValue: newValue });
    }

    private onLongTermCorrosionPeriodFieldUpdated(newValue: string | null) {
        this.setState({ longTermCorrosionPeriodFieldValue: newValue });
    }

    private onEquipmentIdFieldUpdated(newValue: string | null) {
        this.setState({ equipmentIdFieldValue: newValue });
    }

    private onCircuitIdFieldUpdated(newValue: string | null) {
        this.setState({ circuitIdFieldValue: newValue });
    }

    private onSubCircuitIdFieldUpdated(newValue: string | null) {
        this.setState({ subCircuitIdFieldValue: newValue });
    }

    private onCmlIdFieldUpdated(newValue: string | null) {
        this.setState({ cmlIdFieldValue: newValue });
    }

    private onMeasurementPositionFieldUpdated(newValue: string | null) {
        this.setState({ measurementPositionFieldValue: newValue });
    }

    private onSapEquipmentNumberFieldUpdated(newValue: string | null) {
        this.setState({ sapEquipmentNumberFieldValue: newValue });
    }

    private onMeasurementSetIdFieldUpdated(newValue: string | null) {
        this.setState({ measurementSetIdFieldValue: newValue });
    }

    private onMatrixLocationFieldUpdated(newValue: string | null) {
        this.setState({ matrixLocationFieldValue: newValue });
    }

    //private onEcho45FieldUpdated(newValue: boolean) {
    //    this.setState({ echo45FieldValue: newValue });
    //}

    //private onEcho100FieldUpdated(newValue: boolean) {
    //    this.setState({ echo100FieldValue: newValue });
    //}

    //private onReach400FieldUpdated(newValue: boolean) {
    //    this.setState({ reach400FieldValue: newValue });
    //}

    private formValuesAreValid() {
        const { dangerThicknessFieldValue, knownStartingThicknessFieldValue, nominalThicknessFieldValue,
            warningThicknessFieldValue, shortTermCorrosionPeriodFieldValue,
            longTermCorrosionPeriodFieldValue, equipmentIdFieldValue, circuitIdFieldValue,
            subCircuitIdFieldValue, cmlIdFieldValue, measurementPositionFieldValue,
            sapEquipmentNumberFieldValue, measurementSetIdFieldValue, matrixLocationFieldValue,
            nameFieldValue, rfidFieldValue, nodeRfidFieldValue0, nodeRfidFieldValue1, nodeRfidFieldValue2, nodeRfidFieldValue3 } = this.state;
        const { positiveNumberValidator, realNumberValidator, integerValidator, lengthValidator, rfidValidator,
            fieldIsRequiredValidator } = this;

        if (!fieldIsRequiredValidator.isValid(nameFieldValue)) return false;
        if (!fieldIsRequiredValidator.isValid(rfidFieldValue)) return false;
        if (!rfidValidator.isValid(rfidFieldValue)) return false;

        if (this.state.typeFieldValue.productName === "MES" && this.props.sensor === null) {
            if (!fieldIsRequiredValidator.isValid(nodeRfidFieldValue0)) return false;
            if (!fieldIsRequiredValidator.isValid(nodeRfidFieldValue1)) return false;
            if (!fieldIsRequiredValidator.isValid(nodeRfidFieldValue2)) return false;
            if (!fieldIsRequiredValidator.isValid(nodeRfidFieldValue3)) return false;

            if (!rfidValidator.isValid(nodeRfidFieldValue0)) return false;
            if (!rfidValidator.isValid(nodeRfidFieldValue1)) return false;
            if (!rfidValidator.isValid(nodeRfidFieldValue2)) return false;
            if (!rfidValidator.isValid(nodeRfidFieldValue3)) return false;
        }

        if (!realNumberValidator.isValid(knownStartingThicknessFieldValue)) return false;
        if (!positiveNumberValidator.isValid(knownStartingThicknessFieldValue)) return false;

        if (!realNumberValidator.isValid(warningThicknessFieldValue)) return false;
        if (!positiveNumberValidator.isValid(warningThicknessFieldValue)) return false;

        if (!realNumberValidator.isValid(dangerThicknessFieldValue)) return false;
        if (!positiveNumberValidator.isValid(dangerThicknessFieldValue)) return false;

        if (!realNumberValidator.isValid(nominalThicknessFieldValue)) return false;
        if (!positiveNumberValidator.isValid(nominalThicknessFieldValue)) return false;

        if (!integerValidator.isValid(shortTermCorrosionPeriodFieldValue)) return false;
        if (!positiveNumberValidator.isValid(shortTermCorrosionPeriodFieldValue)) return false;

        if (!integerValidator.isValid(longTermCorrosionPeriodFieldValue)) return false;
        if (!positiveNumberValidator.isValid(longTermCorrosionPeriodFieldValue)) return false;

        // TODO: Can we use validator?
        if (!this.getAvailableThicknessOptions().includes(this.state.thicknessCalculationModeFieldValue)) return false;

        if (!lengthValidator.isValid(equipmentIdFieldValue)) return false;
        if (!lengthValidator.isValid(circuitIdFieldValue)) return false;
        if (!lengthValidator.isValid(subCircuitIdFieldValue)) return false;
        if (!lengthValidator.isValid(cmlIdFieldValue)) return false;
        if (!lengthValidator.isValid(measurementPositionFieldValue)) return false;
        if (!lengthValidator.isValid(sapEquipmentNumberFieldValue)) return false;
        if (!lengthValidator.isValid(measurementSetIdFieldValue)) return false;
        if (!lengthValidator.isValid(matrixLocationFieldValue)) return false;

        return true;
    }

    private formValuesAreUnchanged() {
        const { dangerThicknessFieldValue, knownStartingThicknessFieldValue, materialFieldValue, nameFieldValue,
            nominalThicknessFieldValue, thicknessCalculationModeFieldValue,
            temperatureCompensationModeFieldValue, useOverrideChirpParameterFieldValue, typeFieldValue, warningThicknessFieldValue,
            shortTermCorrosionPeriodFieldValue, longTermCorrosionPeriodFieldValue, equipmentIdFieldValue,
            circuitIdFieldValue, subCircuitIdFieldValue, cmlIdFieldValue, measurementPositionFieldValue,
            sapEquipmentNumberFieldValue, measurementSetIdFieldValue, matrixLocationFieldValue } = this.state;
        const { dangerThicknessFieldInitialValue, knownStartingThicknessFieldInitialValue, materialFieldInitialValue,
            nameFieldInitialValue, nominalThicknessFieldInitialValue,
            thicknessCalculationModeFieldInitialValue,
            temperatureCompensationModeFieldInitialValue, useOverrideChirpParameterFieldInitialValue,
            typeFieldInitialValue, warningThicknessFieldInitialValue, shortTermCorrosionPeriodFieldInitialValue,
            longTermCorrosionPeriodFieldInitialValue, equipmentIdFieldInitialValue, circuitIdFieldInitialValue,
            subCircuitIdFieldInitialValue, cmlIdFieldInitialValue, measurementPositionFieldInitialValue,
            sapEquipmentNumberFieldInitialValue, measurementSetIdFieldInitialValue, matrixLocationFieldInitialValue } = this;

        if (nameFieldInitialValue !== nameFieldValue) return false;
        if (typeFieldInitialValue.id !== typeFieldValue.id) return false;
        if (materialFieldInitialValue.id !== materialFieldValue.id) return false;
        if (thicknessCalculationModeFieldInitialValue !== thicknessCalculationModeFieldValue) return false;
        if (temperatureCompensationModeFieldInitialValue !== temperatureCompensationModeFieldValue) return false;//
        if (useOverrideChirpParameterFieldInitialValue !== useOverrideChirpParameterFieldValue) return false;//
        if (knownStartingThicknessFieldValue !== knownStartingThicknessFieldInitialValue) return false;
        if (warningThicknessFieldValue !== warningThicknessFieldInitialValue) return false;
        if (dangerThicknessFieldValue !== dangerThicknessFieldInitialValue) return false;
        if (nominalThicknessFieldValue !== nominalThicknessFieldInitialValue) return false;
        if (shortTermCorrosionPeriodFieldValue !== shortTermCorrosionPeriodFieldInitialValue) return false;
        if (longTermCorrosionPeriodFieldValue !== longTermCorrosionPeriodFieldInitialValue) return false;
        if (longTermCorrosionPeriodFieldValue !== longTermCorrosionPeriodFieldInitialValue) return false;
        if (equipmentIdFieldValue !== equipmentIdFieldInitialValue) return false;
        if (circuitIdFieldValue !== circuitIdFieldInitialValue) return false;
        if (subCircuitIdFieldValue !== subCircuitIdFieldInitialValue) return false;
        if (cmlIdFieldValue !== cmlIdFieldInitialValue) return false;
        if (measurementPositionFieldValue !== measurementPositionFieldInitialValue) return false;
        if (sapEquipmentNumberFieldValue !== sapEquipmentNumberFieldInitialValue) return false;
        if (measurementSetIdFieldValue !== measurementSetIdFieldInitialValue) return false;
        if (matrixLocationFieldValue !== matrixLocationFieldInitialValue) return false;

        return true;
    }
}
