import * as React from "react";
import { Component } from "react";
import { useEffect, useState } from "react";
import SimplePanel from "../Panels/SimplePanel";
import CloseButton from "../Components/Buttons/Composite/CloseButton";
import { Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab/Tab";
import TabPanel from "Panels/Shared/TabPanel";
import RDCDeploymentLogs from "../RDC/RDCDeploymentLogs";
import RDCDataExtractionLogs from "../RDC/RDCDataExtractionLogs";
import UiSettings from "Model/UiSettings";
import DcUserListView from "../DC/DcUserListPanel";
import RDCDeviceManagerForm from "../RDC/RDCDeviceManagerForm";
import DcDeviceManagerForm from "../DC/DcDeviceManagerForm";
import Services from "Services/Platform/Services";
import SensorGroup from "../Model/SensorGroup";
import { ManifestType } from "./manifest";
import "animate.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import RDCUploads from "DC/RDCUploads";

interface DeploymentsDashboardProps {
    onDbUploadRequired(): Promise<void>;
    onGatewayClick?(): void;
    onClose(): void;
    uiSettings: UiSettings;
    manifest: ManifestType;
    areGatewaysEnabled?: boolean;
}

interface DeploymentsDashboardState {
    value: number;
    rootSensorGroup?: SensorGroup;
}

const devicesTabBarStyle = {
        background:"#fff",
        width: "224px",
        minHeight: "unset",
        height:"100vh",
        position:"fixed",
        zIndex:"5",
        padding: "14px 0 10px",
    "& .MuiButtonBase-root":{
        minWidth: "184px",
        textTransform: "none",
        alignItems: "unset",
        flexDirection: "unset",
        justifyContent: "center",
        "&:hover": {
            background: "#e4e5e7",
            borderRadius: "16px",
        },
    },
    "& .Mui-selected": {
        backgroundColor: "#ec6f41",
        color: "#fff !important",
        borderRadius: "16px",
        "&:hover": {
            backgroundColor: "#a54d2d",
            borderRadius: "16px",
        },
    },
    "& .MuiTab-root": {
        minHeight: "unset",
        margin: "10px 20px",
        padding: "5px 13px",
    },
    "& .MuiTabs-indicator": {
        display: "none",
    }
}

const DeviceManagerHome = (props: { sendTabValue: (value: number) => void; manifest: ManifestType }) => {

    const { sendTabValue,manifest } = props;
    const  imgArray = manifest.deviceHomeImgs;

    const [rdcCount,setRdcCount] = useState(0);
    const [hdcCount,setHdcCount] = useState(0);

    const hdcImg = "https://static.inductosense.cloud/idart/images/Inductosense-HDC-Sensor.png";
    const rdcImg = "https://static.inductosense.cloud/idart/images/Inductosense-RDC.png";

    const devicesArray = [
            { img: rdcImg,
              title: "RDC Management",
              content:"WAND-RDC is a remote data collector for automatic retrieval of up to 8 WAND sensors readings.",
              message: `There are ${rdcCount} RDCs.`,
              type: 1,
              btnText: "View Devices"
            },
            { img: hdcImg,
              title: "HDC Management",
              content: "WAND-HDC is a handheld data collector that supports individual sensor readings.",
              message: `There are ${hdcCount} HDCs.`,
              btnText: "View Devices",
              type: 2,
            },
    ]
    
    const getDevicesOverview = async () => {
        const deviceData =await Services.Devices.getDevicesOverview();
        setRdcCount( deviceData.rdcCount );
        setHdcCount( deviceData.hdcCount );
    }

    useEffect( ()=>{
        getDevicesOverview();
    },[])

    const handleItemClick = (value: number) => {
        console.log(value,"value")
        sendTabValue(value)
    }

    console.log(imgArray[1]?.imageUrl,"118")
      
    
    return <div style={{width: "100%", }}>
        <div className="device-img" 
            style={{ marginBottom:"35px", 
            backgroundImage: `url(${imgArray[1]?.imageUrl})`,
            backgroundPosition:"center",
            backgroundRepeat:"noRepeat",
            width:"100%",height:"45vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            fontSize:"20px",
            }}>
            <div className="animate__animated animate__fadeIn animate__slower">
               <h2 style={{fontSize:"16px",lineHeight:"22px",fontWeight:"500",textShadow:"0 0 4px rgba(0,0,0,0.1)",color:"#fff"}}> Inductosense WAND technology for precise wall thickness monitoring.</h2>
               <p style={{fontSize:"14px",lineHeight:"17px",fontWeight:"400",color:"rgba(255,255,255,0.8)"}}>Currently used in a variety of industries including oil and gas, chemicals, mining, renewable energy and nuclear energy.</p>
            </div>
        </div>
        <div style={{ width: "100%", display:"flex",height:"30vh",minHeight:"210px"}}>
            {devicesArray.map((item,index) => {
                return  <div style={{flex: "1",padding:  "5px", boxShadow: index===0?"2px 1px 7px 0 #242c5c":"2px 1px 7px 0 #ec6f41", background: "#F6F9fB",marginRight: index===0?"20px":"",display:"flex"}} key={index}>
                    <div style={{background:"#fff",padding:"25px"}}>
                        <img src={item.img} style={{height: "100%"}} />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <div style={{ margin: "10px",fontSize: "22px",fontWeight: "bolder",color:index===0?"#242c5c":"#EC6f41"}}>{item.title}</div>
                        <div style={{ margin: "10px",color:index===0?"#242c5c":"#EC6f41",fontSize:"18px", fontWeight: "bolder"}}>{item.message}</div>
                        <div style={{ margin: "10px",fontSize:"14px",lineHeight:"25px",}}>{item.content}</div>
                        <div style={{marginLeft:"10px"}}>
                            <Button
                                variant="contained"
                                onClick={()=>handleItemClick(item.type)} 
                                sx = {{
                                    textTransform: "none",
                                    borderRadius:"15px",
                                    height:"28px",
                                    "& .MuiButtonBase-root":{
                                        textTransform: "none",
                                        borderRadius:"15px",
                                        height:"30px"
                                    },
                                    "&:hover": {
                                        background: "#F9EDE7",
                                        color:"#ec6f41",
                                    },
                                    textAlign:"center",
                                    border:"1px solid #ec6f41",
                                    backgroundColor: "#ec6f41",
                                    color: "#fff",
                                }}
                            >{item.btnText}
                            </Button>
                        </div>
                    </div>
                </div>
                })
            }
        </div>
    </div>
}
export default class DeploymentsDashboard extends Component<DeploymentsDashboardProps, DeploymentsDashboardState> {
    constructor(props: DeploymentsDashboardProps) {
        super(props);

        this.state = {
            value: 0
        };
    }

    async componentDidMount() {
        const rootSensorGroup = await Services.SensorGroups.getRootSensorGroup();
        this.setState({
            rootSensorGroup
        });
    }


    render() {
        return <SimplePanel
            isDevice={true}
            isNewStyleTitle={true}
            title="Device Manager"
            titleAlignment="centre"
            actionButton={this.closeButton()}
            content={this.content()}
            shouldApplyPadding={false}
        />
    }

    private closeButton() {
        return <CloseButton isDevice={true} className="deploymentsDashboardCloseButton" onClick={() => this.props.onClose()} />;
    }

    handleChange(_ev: React.ChangeEvent<object>, newValue: number) {
        this.setState({ value: newValue });
    }

    
    handleItemClick(tabValue:number){
        this.setState({ value: tabValue});
    }

    private content() {
        const { value, rootSensorGroup } = this.state;

        return (
            <div>
                <Tabs value={value} orientation="vertical" onChange={this.handleChange.bind(this)} sx={devicesTabBarStyle}>
                    <Tab tabIndex={0} label="Home" id="tab-home" />
                    <Tab tabIndex={1} label="RDC Management" id="tab-RDC" />
                    <Tab tabIndex={2} label="HDC Management" id="tab-HDC" />
                    <Tab tabIndex={3} label="RDC Uploads" id="tab-rdc-uploads" style={Services.userHasPolicy("ImportUploadedReadings") ? {} : { "display": "none" }} />
                    <Tab tabIndex={4} label="DC Users" id="tab-data-dc-users" />
                    <Tab tabIndex={5} label="Configuration Logs" id="tab-config-logs" />
                    <Tab tabIndex={6} label="Data Extraction Logs" id="tab-data-extraction-logs" />
                </Tabs>
                <TabPanel value={value} index={0} isDevice={true}>
                    <DeviceManagerHome sendTabValue={this.handleItemClick.bind(this)} manifest={ this.props.manifest }/>
                </TabPanel>
                <TabPanel value={value} index={1} isDevice={true}>
                    {value === 1 ? <RDCDeviceManagerForm onDbUploadRequired={this.props.onDbUploadRequired} areGatewaysEnabled={this.props.areGatewaysEnabled} uiSettings={this.props.uiSettings} onGatewayClick={this.props.onGatewayClick} rootSensorGroup={rootSensorGroup} /> : null}
                </TabPanel>
                <TabPanel value={value} index={2} isDevice={true}>
                    {value === 2  ? <DcDeviceManagerForm uiSettings={this.props.uiSettings} rootSensorGroup={rootSensorGroup} /> : null}
                </TabPanel>
                <TabPanel value={value} index={3} isDevice={true}>
                    {value === 3  ? <RDCUploads uiSettings={this.props.uiSettings} rootSensorGroup={rootSensorGroup} /> : null}
                </TabPanel>
                <TabPanel value={value} index={4} isDevice={true}>
                    {value === 4 ?<DcUserListView /> : null}
                </TabPanel>
                <TabPanel value={value} index={5} isDevice={true}>
                    {value === 5 ?<RDCDeploymentLogs /> : null}
                </TabPanel>
                <TabPanel value={value} index={6} isDevice={true}>
                    {value === 6 ?<RDCDataExtractionLogs uiSettings={this.props.uiSettings} /> : null}
                </TabPanel>
            </div>
        );
    }
}
